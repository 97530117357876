export const DICH_VU = "dich_vu"
const FORM_ROUTER = {
  // Router
  TO_KHAI_DANG_KY_SANG_CHE: `/${DICH_VU}/to-khai-dang-ky-sang-che`,
  TO_KHAI_DANG_KY_GPHI: `/${DICH_VU}/to-khai-dang-ky-giai-phap-huu-ich`,
  TO_KHAI_DANG_KY_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-dang-ky-kieu-dang-cong-nghiep`,
  TO_KHAI_DANG_KY_NHAN_HIEU: `/${DICH_VU}/to-khai-dang-ky-nhan-hieu`,
  TO_KHAI_DANG_KY_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-dang-ky-chi-dan-dia-ly`,
  TO_KHAI_DANG_KY_THIET_KE_BO_TRI_MACH_TICH_HOP_BAN_DAN: `/${DICH_VU}/to-khai-dang-ky-thiet-ke-bo-tri-mach-tich-hop-ban-dan`,
  //CCĐD - Đơn yêu cầu cấp chung chi hành nghe dịch vụ dài diện số hụu công nghiệp
  TO_KHAI_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-chung-chi-hanh-nghe-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-the-giam-dinh-vien-so-huu-cong-nghiep`,
  //CCGĐ - Cấp thẻ giám định viên
  TO_KHAI_YEU_CAU_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-the-giam-dinh-vien-so-huu-cong-nghiep`,
  //DT2 - Đơn yêu cầu duy trì hiệu lực VBBH - Giải pháp hữu ích
  TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-gia-han-hieu-luc-van-bang-bao-ho-giai-phap-huu-ich`,
  //Đơn ĐC
  //ĐC1 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền sang che
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-chu-vbbh-tuyen-bo-tu-bo-quyen-sang-che`,

  //ĐC2 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền giải pháp hữu ích
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_GIA_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-chu-vbbh-tuyen-bo-tu-bo-quyen-giai-phap-huu-ich`,

  //ĐC3 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-chu-vbbh-tuyen-bo-tu-bo-quyen-kieu-dang-cong-nghiep`,

  //ĐC4 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền nhãn hiệu
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-chu-vbbh-tuyen-bo-tu-bo-quyen-nhan-hieu`,

  //ĐC6 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền chỉ dẫn địa lý
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-chu-vbbh-tuyen-bo-tu-bo-quyen-chi-dan-dia-ly`,

  //ĐC7 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền thiết kế bố trí
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-chu-vbbh-tuyen-bo-tu-bo-quyen-thiet-ke-bo-tri`,

  //Đơn ĐN
  //ĐN1 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) sang che
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-sang-che`,

  //ĐN2 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) giải pháp hữu ích
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_GIA_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-giai-phap-huu-ich`,

  //ĐN3 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-kieu-dang-cong-nghiep`,

  //ĐN4 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) nhãn hiệu
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-nhan-hieu`,

  //ĐN5 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) nhãn hiệu quốc tế
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_NHAN_HIEU_QUOC_TE: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-nhan-hieu-nhan-hieu-quoc-te`,

  //ĐN6 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) chỉ dẫn địa lý
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-chi-dan-dia-ly`,

  //ĐN7 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) thiết kế bố trí
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-thiet-ke-bo-tri`,

  //ĐN8 - Đơn yêu cầu chấm dứt hiệu lực VBBH (do bên thứ 3 yêu cầu) LAHAY
  TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_LAHAY: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-hieu-luc-vbbh-do-ben-thu-ba-yeu-cau-LAHAY`,

  //==================================
  // Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN
  // CB1 - Đơn đăng ký - Sáng chế
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_SANG_CHE: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong-quyen-shcn-sang-che`,
  // CB2 - Đơn đăng ký - Giải pháp hữu ích
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong-quyen-shcn-giai-phap-huu-ich`,
  // CB3 - Đơn đăng ký - Kiểu dáng công nghiệp
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong-quyen-shcn-kieu-dang-cong-nghiep`,
  // CB4 - Đơn đăng ký - Nhãn hiệu
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_NHAN_HIEU: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong-quyen-shcn-nhan-hieu`,
  // CB7 - Đơn đăng ký - Thiết kế bố trí
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong-quyen-shcn-thiet-ke-bo-tri`,

  //==================================

  // LX1 -  Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_SANG_CHE: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-sang-che`,
  // LX2 - Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-giai-phap-huu-ich`,
  // LX3 - Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-kieu-dang-cong-nghiep`,
  // LX4 - Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_NHAN_HIEU: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-nhan-hieu`,
  // LX7 - Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-thiet-ke-bo-tri`,

  //==================================
  //Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN
  // DCLX1 - Đơn yêu cầu chấm dứt - Sáng chế
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-cham-dut-hieu-luc-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-sang-che`,
  // DCLX2 - Đơn yêu cầu chấm dứt - Giải pháp hữu ích
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-cham-dut-hieu-luc-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-giai-phap-huu-ich`,
  // DCLX3 - Đơn yêu cầu chấm dứt - Kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-cham-dut-hieu-luc-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-kieu-dang-cong-nghiep`,
  // DCLX4 - Đơn yêu cầu chấm dứt - Nhãn hiệu
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-cham-dut-hieu-luc-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-nhan-hieu`,
  // DCLX7 - Đơn yêu cầu chấm dứt - Thiết kế bố trí
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-cham-dut-hieu-luc-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  //==================================
  // Đơn yêu cầu gia hạn hợp đồng chuyển quyền sử dụng đối tượng SHCN
  // GHLX1 - Đơn yêu cầu gia hạn - Sáng chế
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-gia-han-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-sang-che`,
  // GHLX2 - Đơn yêu cầu gia hạn - Giải pháp hữu ích
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-gia-han-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-giai-phap-huu-ich`,
  // GHLX3 - Đơn yêu cầu gia hạn - Kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-gia-han-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-kieu-dang-cong-nghiep`,
  // GHLX4 - Đơn yêu cầu gia hạn - Nhãn hiệu
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-gia-han-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-nhan-hieu`,
  // GHLX7 - Đơn yêu cầu gia hạn - Thiết kế bố trí
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-gia-han-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  //==================================
  // Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN
  // SĐLX1 - Đơn yêu cầu sửa đổi - Sáng chế
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-sua-doi-noi-dung-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-sang-che`,
  // SĐLX2 - Đơn yêu cầu sửa đổi - Giải pháp hữu ích
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-sua-doi-noi-dung-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-giai-phap-huu-ich`,
  // SĐLX3 - Đơn yêu cầu sửa đổi - Kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-sua-doi-noi-dung-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-kieu-dang-cong-nghiep`,
  // SĐLX4 - Đơn yêu cầu sửa đổi - Nhãn hiệu
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-sua-doi-noi-dung-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-nhan-hieu`,
  // SĐLX7 - Đơn yêu cầu sửa đổi - Thiết kế bố trí
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-sua-doi-noi-dung-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  //==================================
  // Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN
  // GNĐB1 - Ghi nhận đăng bạ - Sáng chế
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep-sang-che`,
  // GNĐB2 - Ghi nhận đăng bạ - Giải pháp hữu ích
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep-giai-phap-huu-ich`,
  // GNĐB3 - Ghi nhận đăng bạ - Kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep-kieu-dang-cong-nghiep`,
  // GNĐB4 - Ghi nhận đăng bạ - Nhãn hiệu
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep-nhan-hieu`,
  // GNĐB6 - Ghi nhận đăng bạ - Chỉ dẫn địa lý
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep-chi-dan-dia-ly`,
  // GNĐB7 - Ghi nhận đăng bạ - Thiết kế bố trí
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  // ĐN1 - Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH
  TO_KHAI_CHAM_DUT_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN: `/${DICH_VU}/to-khai-cham-dut-hieu-luc-van-bang-bao-ho-shcn`,

  // A - Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VN_A: `/${DICH_VU}/to-khai-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-a`,
  // B - Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VN_B: `/${DICH_VU}/to-khai-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-b`,
  // P - Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VN_P: `/${DICH_VU}/to-khai-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-p`,

  // KTGĐ - Đăng ký dự kiểm tra nghiệp vụ giám định sở hữu công nghiệp
  TO_KHAI_THU_TUC_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-thu-tuc-dang-ky-du-kiem-tra-nghiep-vu-giam-dinh-so-huu-cong-nghiep`,

  // KTNV - Đăng ký dự kiểm tra nghiệp vụ đại diện sở hữu công nghiệp
  TO_KHAI_DANG_KY_KIEM_TRA_NGHIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-dang-ky-du-kiem-tra-nghiep-vu-dai-dien-so-huu-cong-nghiep`,

  //CĐ1 - Chuyển giao đơn - Sáng chế
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-nguoi-nop-don-dang-ky-doi-tuong-so-huu-cong-nghiep-sang-che`,
  //CĐ2 - Chuyển giao đơn - Giải pháp hữu ích
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-nguoi-nop-don-dang-ky-doi-tuong-so-huu-cong-nghiep-giai-phap-huu-ich`,

  //CĐ3 - Chuyển giao đơn - Kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-nguoi-nop-don-dang-ky-doi-tuong-so-huu-cong-nghiep-kieu-dang-cong-nghiep`,
  //CĐ4 - Chuyển giao đơn - Nhãn hiệu
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-nguoi-nop-don-dang-ky-doi-tuong-so-huu-cong-nghiep-nhan-hieu`,
  //CĐ7 - Chuyển giao đơn - Thiết kế bố trí
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-nguoi-nop-don-dang-ky-doi-tuong-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  //Đơn khiếu nại của đơn liên quan tới đơn
  //KN1: "Đơn khiếu nại - Sáng chế",
  TO_KHAI_KHIEU_NAI_SANG_CHE_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-sang-che-don-lien-quan-toi-don`,
  // KN2: "Đơn khiếu nại - Giải pháp hữu ích",
  TO_KHAI_KHIEU_NAI_GIAI_PHAP_HUU_ICH_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-giai-phap-huu-ich-don-lien-quan-toi-don`,
  // KN3: "Đơn khiếu nại - Kiểu dáng công nghiệp",
  TO_KHAI_KHIEU_NAI_KIEU_DANG_CONG_NGHIEP_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-kieu-dang-cong-nghiep-don-lien-quan-toi-don`,
  // KN4: "Đơn khiếu nại - Nhãn hiệu",
  TO_KHAI_KHIEU_NAI_NHAN_HIEU_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-nhan-hieu-don-lien-quan-toi-don`,
  // KN5: "Đơn khiếu nại - Nhãn hiệu quốc tế",
  TO_KHAI_KHIEU_NAI_NHAN_HIEU_QUOC_TE_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-nhan-hieu-quoc-te-don-lien-quan-toi-don`,
  // KN6: "Đơn khiếu nại - Chỉ dẫn địa lý",
  TO_KHAI_KHIEU_NAI_CHI_DAN_DIA_LY_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-chi-dan-dia-ly-don-lien-quan-toi-don`,
  // KN7: "Đơn khiếu nại - Thiết kế bố trí",
  TO_KHAI_KHIEU_NAI_THIET_KE_BO_TRI_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-thiet-ke-bo-tri-don-lien-quan-toi-don`,
  // KN8: "Đơn khiếu nại - LAHAY",
  TO_KHAI_KHIEU_NAI_LAHAY_DON_LIEN_QUAN_TOI_DON: `/${DICH_VU}/to-khai-khieu-nai-lahay-don-lien-quan-toi-don`,

  //Đơn khiếu nại của đơn liên quan tới văn bằng
  //KN1: "Đơn khiếu nại - Sáng chế",
  TO_KHAI_KHIEU_NAI_SANG_CHE_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-sang-che-don-lien-quan-toi-van-bang`,
  // KN2: "Đơn khiếu nại - Giải pháp hữu ích",
  TO_KHAI_KHIEU_NAI_GIAI_PHAP_HUU_ICH_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-giai-phap-huu-ich-don-lien-quan-toi-van-bang`,
  // KN3: "Đơn khiếu nại - Kiểu dáng công nghiệp",
  TO_KHAI_KHIEU_NAI_KIEU_DANG_CONG_NGHIEP_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-kieu-dang-cong-nghiep-don-lien-quan-toi-van-bang`,
  // KN4: "Đơn khiếu nại - Nhãn hiệu",
  TO_KHAI_KHIEU_NAI_NHAN_HIEU_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-nhan-hieu-don-lien-quan-toi-van-bang`,
  // KN5: "Đơn khiếu nại - Nhãn hiệu quốc tế",
  TO_KHAI_KHIEU_NAI_NHAN_HIEU_QUOC_TE_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-nhan-hieu-quoc-te-don-lien-quan-toi-van-bang`,
  // KN6: "Đơn khiếu nại - Chỉ dẫn địa lý",
  TO_KHAI_KHIEU_NAI_CHI_DAN_DIA_LY_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-chi-dan-dia-ly-don-lien-quan-toi-van-bang`,
  // KN7: "Đơn khiếu nại - Thiết kế bố trí",
  TO_KHAI_KHIEU_NAI_THIET_KE_BO_TRI_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-thiet-ke-bo-tri-don-lien-quan-toi-van-bang`,
  // KN8: "Đơn khiếu nại - LAHAY",
  TO_KHAI_KHIEU_NAI_LAHAY_DON_LIEN_QUAN_TOI_VAN_BANG: `/${DICH_VU}/to-khai-khieu-nai-lahay-don-lien-quan-toi-van-bang`,

  //SB1 - Tờ khai sửa đổi VBBH - Sáng chế
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_SANG_CHE: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho-sang-che`,
  //SB2 - Tờ khai sửa đổi VBBH - Giải pháp hữu ích
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho-giai-phap-huu-ich`,
  //SB3 - Tờ khai sửa đổi VBBH - Kiểu dáng công nghiệp
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho-kieu-dang-cong-nghiep`,
  //SB4 - Tờ khai sửa đổi VBBH - Nhãn hiệu
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_NHAN_HIEU: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho-nhan-hieu`,
  //SB6 - Tờ khai sửa đổi VBBH - Chỉ dẫn địa lí
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho-chi-dan-dia-ly`,
  //SB7 - Tờ khai sửa đổi VBBH - Thiết kế bố trí
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho-thiet-ke-bo-tri`,

  //==================================
  // DT1 - Đơn yêu cầu duy trì hiệu lực VBBH - Sáng chế
  TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO_SANG_CHE: `/${DICH_VU}/to-khai-duy-tri-hieu-luc-van-bang-bao-ho-sang-che`,
  //SĐ1 - Sửa đổi đơn - Sáng chế
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep_sang_che`,
  // SĐ2 - Sửa đổi đơn - Giải pháp hữu ích
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep_giai_phap_huu_ich`,
  // SĐ3 - Sửa đổi đơn - Kiểu dáng cồng nghiệp
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep_kieu_dang_cong_nghiep`,
  // SĐ4 - Sửa đổi đơn - Nhãn hiệu
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep_nhan_hieu`,
  // SĐ6 - Sửa đổi đơn - Chỉ dẫn địa lý
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_CHI_DAN_DIA_LY: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep_chi_dan_dia_ly`,
  // SĐ7 - Sửa đổi đơn - Thiết kế bố trí
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep_thiet_ke_bo_tri`,
  // LXBB - Đơn yêu cầu bắt buộc chuyển giao quyền sử dụng sáng chế
  TO_KHAI_YEU_CAU_BAT_BUOC_CHUYEN_GIAO_QUYEN_SU_DUNG_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-bat-buoc-chuyen-giao-quyen-su-dung-sang-che`,

  // SĐĐD - Đơn đăng ký ghi nhận thay đổi về tổ chức dịch vụ đại diện sở hữu công nghiệp/người đại diện SHCN
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_THONG_TIN_CUA_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-thong-tin-cua-to-chuc-dich-vu-dai-dien-so-huu-cong-nghiep`,
  //TCGĐ - Đơn đăng ký yêu cầu cấp giấy chứng nhận tổ chức đủ điều kiện hoạt động giám định SHCN
  TO_KHAI_YEU_CAU_CAP_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-giay-chung-nhan-to-chuc-du-dieu-kien-hoat-dong-giam-dinh-so-huu-cong-nghiep`,
  // TCĐD - Đơn đăng ký ghi nhận tổ chức dịch vụ đại diện sở hữu công nghiệp
  TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DU_DIEU_KIEN_KINH_DOANH_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-to-chuc-du-dieu-kien-kinh-doanh-dich-vu-dai-dien-so-huu-cong-nghiep`,
  // CLCC - Cấp lại chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
  TO_KHAI_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-chung-chi-hanh-nghe-dich-vu-dai-dien-so-huu-cong-nghiep`,

  //PB1 - Đơn yêu cầu cấp phó bản VBBH - sáng chế
  TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE: `/${DICH_VU}/to-khai-cap-pho-ban-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep-sang-che`,
  //PB2 - Đơn yêu cầu cấp phó bản VBBH - Giải pháp hữu ích
  TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-cap-pho-ban-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep-giai-phap-huu-ich`,
  //PB3 - Đơn yêu cầu cấp phó bản VBBH - Kiểu dáng công nghiệp
  TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-cap-pho-ban-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep-kieu-dang-cong-nghiep`,
  //PB4 - Đơn yêu cầu cấp phó bản VBBH - Nhãn hiệu
  TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU: `/${DICH_VU}/to-khai-cap-pho-ban-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep-nhan-hieu`,
  //PB7 - Đơn yêu cầu cấp phó bản VBBH - Thiết kế bố trí
  TO_KHAI_CAP_PHO_BAN_CAP_LAI_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-cap-pho-ban-cap-lai-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  //=========================
  // Đơn yêu cầu gia hạn hiệu lực VBBH
  //GH3 - Tờ khai gia hạn VBBH - Kiểu dáng công nghiệp
  TO_KHAI_GIA_HAN_VAN_BANG_BAO_HO_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-gia-han-van-bang-bao-ho-kieu-dang-cong-nghiep`,
  //GH4 - Tờ khai gia hạn VBBH - Nhãn hiệu
  TO_KHAI_GIA_HAN_VAN_BANG_BAO_HO_NHAN_HIEU: `/${DICH_VU}/to-khai-gia-han-van-bang-bao-ho-nhan-hieu`,
  TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-cap-pho-ban-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep-thiet-ke-bo-tri`,

  //RB1 - Đơn yêu cầu cấp lại VBBH - Sáng chế
  TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_SANG_CHE: `/${DICH_VU}/to-khai-cap-lai-van-bang-bao-ho-sang-che`,
  //RB2 - Đơn yêu cầu cấp lại VBBH - Giải pháp hữu ích
  TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-cap-lai-van-bang-bao-ho-giai-phap-huu-ich`,
  //RB3 - Đơn yêu cầu cấp lại VBBH - Kiểu dáng công nghiệp
  TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-cap-lai-van-bang-bao-ho-kieu-dang-cong-nghiep`,
  //RB4 - Đơn yêu cầu cấp lại VBBH - Nhãn hiệu
  TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_NHAN_HIEU: `/${DICH_VU}/to-khai-cap-lai-van-bang-bao-ho-nhan-hieu`,
  //RB6 - Đơn yêu cầu cấp lại VBBH - Chỉ dẫn địa lý
  TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-cap-lai-van-bang-bao-ho-chi-dan-dia-ly`,
  //RB7 - Đơn yêu cầu cấp lại VBBH - Thiết kế bố trí
  TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-cap-lai-van-bang-bao-ho-thiet-ke-bo-tri`,

  //RBLX1 - Đơn yêu cầu cấp lại Hợp đồng chuyển quyền SHCN - Sáng chế
  TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_SANG_CHE: `/${DICH_VU}/to-khai-cap-lai-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-sang-che`,
  //RBLX2 - Đơn yêu cầu cấp lại Hợp đồng chuyển quyền SHCN - Giải pháp hữu ích
  TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-cap-lai-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-giai-phap-huu-ich`,
  //RBLX3 - Đơn yêu cầu cấp lại Hợp đồng chuyển quyền SHCN - Kiểu dáng công nghiệp
  TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-cap-lai-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-kieu-dang-cong-nghiep`,
  //RBLX4 - Đơn yêu cầu cấp lại Hợp đồng chuyển quyền SHCN - Nhãn hiệu
  TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_NHAN_HIEU: `/${DICH_VU}/to-khai-cap-lai-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-nhan-hieu`,
  //RBLX7 - Đơn yêu cầu cấp lại Hợp đồng chuyển quyền SHCN - Thiết kế
  TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_THIET_KE: `/${DICH_VU}/to-khai-cap-lai-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-thiet-ke`,

  //RBPB1 - Đơn yêu cầu cấp lại phó bản VBBH - Sáng chế
  TO_KHAI_CAP_LAI_PHO_BAN_VBBH_SANG_CHE: `/${DICH_VU}/to-khai-cap-lai-pho-ban-van-bang-bao-ho-sang-che`,
  //RBPB2 - Đơn yêu cầu cấp lại phó bản VBBH - Giải pháp hữu ích
  TO_KHAI_CAP_LAI_PHO_BAN_VBBH_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-cap-lai-pho-ban-van-bang-bao-ho-giai-phap-huu-ich`,
  //RBPB3 - Đơn yêu cầu cấp lại phó bản VBBH - Kiểu dáng công nghiệp
  TO_KHAI_CAP_LAI_PHO_BAN_VBBH_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-cap-lai-pho-ban-van-bang-bao-ho-kieu-dang-cong-nghiep`,
  //RBPB4 - Đơn yêu cầu cấp lại phó bản VBBH - Nhãn hiệu
  TO_KHAI_CAP_LAI_PHO_BAN_VBBH_NHAN_HIEU: `/${DICH_VU}/to-khai-cap-lai-pho-ban-van-bang-bao-ho-nhan-hieu`,
  //RBPB7 - Đơn yêu cầu cấp lại phó bản VBBH - Thiết kế bố trí
  TO_KHAI_CAP_LAI_PHO_BAN_VBBH_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-cap-lai-pho-ban-van-bang-bao-ho-thiet-ke-bo-tri`,

  //PBLX1 - Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế
  TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-cap-pho-ban-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-sang-che`,
  //PBLX2 - Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích
  TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_GIAI_PHAP_HUU_ICH: `/${DICH_VU}/to-khai-yeu-cau-cap-pho-ban-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-giai-phap-huu-ich`,
  //PBLX3 - Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp
  TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-pho-ban-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-kieu-dang-cong-nghiep`,
  //PBLX4 - Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu
  TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_NHAN_HIEU: `/${DICH_VU}/to-khai-yeu-cau-cap-pho-ban-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-nhan-hieu`,
  //PBLX7 - Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí
  TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_THIET_KE_BO_TRI: `/${DICH_VU}/to-khai-yeu-cau-cap-pho-ban-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn-thiet-ke-bo-tri`,

  //PM - Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam"
  TO_KHAI_YEU_CAU_SUA_DOI_TEN_DIA_CHI_DAI_DIEN_SHCN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_PM: `/${DICH_VU}/to-khai-yeu-cau-sua-doi-ten-dia-chi-dai-dien-shcn-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-pm`,
  //PL - Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt
  TO_KHAI_YEU_CAU_GIOI_HAN_HAN_CHE_DANH_MUC_SAN_PHAM_DICH_VU_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_PL: `/${DICH_VU}/to-khai-yeu-cap-gioi-han-han-che-danh-muc-san-pham-dich-vu-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-pl`,
  //PR - YÊU CẦU GIA HẠN ĐĂNG KÝ QUỐC TẾ NHÃN HIỆU CÓ NGUỒN GỐC VIỆT NAM
  TO_KHAI_YEU_CAU_GIA_HAN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_PR: `/${DICH_VU}/to-khai-yeu-cau-gia-han-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-pr`,
  //PS - Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_MO_RONG_LANH_THO_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_PS: `/${DICH_VU}/to-khai-yeu-cau-mo-rong-lanh-tho-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-ps`,
  //PT - Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_CHUYEN_NHUONG_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_PT: `/${DICH_VU}/to-khai-chuyen-nhuong-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-pt`,
  //PC - Đơn hủy bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_HUY_BO_HIEU_LUC_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_PC: `/${DICH_VU}/to-khai-huy-bo-hieu-luc-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-pc`,

  //SĐGĐ - Đơn yêu cầu cấp lại Giấy chứng nhận tổ chức đủ điều kiện hoạt động giám định sở hữu công nghiệp
  TO_KHAI_YEU_CAU_CAP_LAI_GIAY_CHUNG_NHAN_TO__CHUC_DU__DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-giay-chung-nhan-to-chuc-du-dieu-kien-hoat-dong-giam-dinh-so-huu-cong-nghiep`,
  //AS - Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_MO_RONG_LANH_THO_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_AS: `/${DICH_VU}/to-khai-yeu-cau-mo-rong-lanh-tho-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-as`,
  //BS - Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_MO_RONG_LANH_THO_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_BS: `/${DICH_VU}/to-khai-yeu-cau-mo-rong-lanh-tho-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-bs`,
  //AC - Đơn huỷ bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_HUY_BO_HIEU_LUC_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_AC: `/${DICH_VU}/to-khai-huy-bo-hieu-luc-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-ac`,
  //AL - Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt
  TO_KHAI_YEU_CAU_GIOI_HAN_HAN_CHE_DANH_MUC_SAN_PHAM_DICH_VU_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_AL: `/${DICH_VU}/to-khai-yeu-cau-gioi-han-han-che-danh-muc-san-pham-dich-vu-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-al`,
  //AM - Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_SUA_DOI_TEN_DIA_CHI_DAI_DIEN_SHCN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_AM: `/${DICH_VU}/to-khai-yeu-cau-sua-doi-ten-dia-chi-dai-dien-shcn-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-am`,
  //AR - Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_GIA_HAN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_AR: `/${DICH_VU}/to-khai-yeu-cau-gia-han-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-ar`,
  //AT - Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_CHUYEN_NHUONG_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_AT: `/${DICH_VU}/to-khai-chuyen-nhuong-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-at`,
  //BC - Đơn hủy bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_HUY_BO_HIEU_LUC_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_BC: `/${DICH_VU}/to-khai-huy-bo-hieu-luc-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-bc`,
  //BL
  TO_KHAI_YEU_CAU_GIOI_HAN_HAN_CHE_DANH_MUC_SAN_PHAM_DICH_VU_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_BL: `/${DICH_VU}/to-khai-yeu-cau-gioi-han-han-che-danh-muc-san-pham-dich-vu-dang-ky-quoc-te-nhan-hieu-co-nugon-goc-viet-nam-bl`,
  //BM - Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_SUA_DOI_TEN_DIA_CHI_DAI_DIEN_SHCN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_BM: `/${DICH_VU}/to-khai-yeu-cau-sua-doi-ten-dia-chi-dai-dien-shcn-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-bm`,
  //BR - Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam
  TO_KHAI_YEU_CAU_GIA_HAN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_BR: `/${DICH_VU}/to-khai-yeu-cau-gia-han-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-br`,
  //BT
  TO_KHAI_CHUYEN_NHUONG_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM_BT: `/${DICH_VU}/to-khai-chuyen-nhuong-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam-bt`,
}
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  FormPDF: "/form-pdf",
  HOME: "/",
  HO_TRO: "/ho-tro",
  XAC_NHAN: "/xac-nhan-thong-tin",
  THU_TUC_HANH_CHINH: "/thu-tuc-hanh-chinh",
  HUONG_DAN_THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN:
    "/thu-tuc-hanh-chinh/thu-tuc-cap-lai-cap-pho-van-bang-bao-ho-shcn",
  DANG_NHAP: "/dang-nhap",
  DANG_KY: "/dang-ky",
  DOI_MAT_KHAU: "/doi-mat-khau",
  TRA_CUU_KET_QUA: "/tra-cuu-ket-qua",
  TEST_PRINT: "/test-print",
  PAYMENT_SUCCESS: "/payment-success",

  //ANONYMOUS
  PREVIEW_PDF: "/preview-pdf",
  QUEN_MAT_KHAU: "/quen-mat-khau",
  // ADMIN
  DANH_BA_TO_CHUC_DAI_DIEN: "/danh-ba-to-chuc-dai-dien",
  DANH_BA_NGUOI_DAI_DIEN: "/danh-ba-nguoi-dai-dien",
  QUAN_LY_PHAN_QUYEN: "/quan-ly-phan-quyen",
  LS_HOAT_DONG: "/ls-hoat-dong",
  DANH_BA_NHAN_VIEN: "/danh-ba-nhan-vien",
  DANH_SACH_CHUC_VU: "/danh-sach-chuc-vu",
  DUYET_DANG_KY: "/duyet-dang-ky",
  DANH_BA_KHACH_HANG: "/danh-ba-khach-hang",
  NHAT_KY_HE_THONG: "/nhat-ky-he-thong",
  YEU_CAU_HO_TRO: "/yeu-cau-ho-tro",
  YEU_CAU_CAP_NHAT: "/yeu-cau-cap-nhat",
  DANH_MUC_HE_THONG: "/danh-muc-he-thong",
  DANH_MUC_HO_SO: "/danh-muc-ho-so",

  KY_TRA_KET_QUA: "/ky-tra-ket-qua",
  KY_TRA_VAN_BANG: "/ky-tra-van-bang",
  HO_SO_TRUC_TUYEN: "/ho-so-truc-tuyen",
  HO_SO_TRUC_TIEP: "/ho-so-truc-tiep",
  HO_SO_TRUC_TUYEN_THU_PHI: "/ho-so-truc-tuyen-thu-phi",
  HO_SO_TRUC_TIEP_THU_PHI: "/ho-so-truc-tiep-thu-phi",
  DANH_SACH_BIEN_LAI: "/danh-sach-bien-lai",
  XEM_DS_HS_IT: "/danh-sach-hs",
  BAO_CAO_THU_PHI: "/bao-cao-thu-phi",
  BAO_CAO_THU_PHI_2: "/bao-cao-thu-phi-2",
  BAO_CAO_DANG_KY: "/bao-cao-dang-ky",
  THONG_KE: "/thong-ke",
  QUAN_LY_QUY: "/quan-ly-quy",
  QUAN_LY_SO_DU: "/quan-ly-so-du",

  //User
  DICH_VU: DICH_VU,
  TAO_TO_KHAI_MOI: `/${DICH_VU}/tao-to-khai-moi`,
  TO_KHAI_MOI: `/${DICH_VU}/to-khai-moi`,
  HO_SO_CHO_XU_LY: `/${DICH_VU}/ho-so-cho-xu-ly`,
  HO_SO_DANG_XU_LY: `/${DICH_VU}/ho-so-dang-xu-ly`,
  NHOM_THANH_TOAN: `/${DICH_VU}/nhom-thanh-toan`,
  DANH_SACH_VAN_BANG: `/${DICH_VU}/danh-sach-van-bang`,
  QUAN_LY_TAI_KHOAN_HO_TRO: `/${DICH_VU}/ho-tro`,
  LS_HOAT_DONG_USER: `/${DICH_VU}/ls-hoat-dong-user`,
  DANH_BA_NGUOI_DAI_DIEN_DV: `/${DICH_VU}/danh-ba-nguoi-dai-dien`,
  THONG_TIN_TAI_KHOAN: `/${DICH_VU}/thong-tin-ca-nhan`,
  CAU_HINH_CHU_KY: `/${DICH_VU}/cau-hinh-chu-ky`,
  DANH_SACH_YEU_CAU_HO_TRO: `/${DICH_VU}/danh-sach-yeu-cau-ho-tro`,
  HUONG_DAN_SU_DUNG: `/${DICH_VU}/huong-dan-su-dung`,
  QUESTION: "/question",
  //HUONG DAN THU TUC
  HUONG_DAN_DANG_KY_SANG_CHE: "/thu-tuc-hanh-chinh/dang-ky-sang-che",
  HUONG_DAN_DANG_KY_SANG_CHE_NGUON_GOC_VN:
    "/thu-tuc-hanh-chinh/dang-ky-sang-che-nguon-goc-vn",
  HUONG_DAN_DANG_KY_SANG_CHE_CHI_DINH_VN:
    "/thu-tuc-hanh-chinh/dang-ky-sang-che-chi-dinh-VN",
  HUONG_DAN_DANG_KY_SANG_CHE_CHON_VN:
    "/thu-tuc-hanh-chinh/dang-ky-sang-che-co-chon-VN",
  HUONG_DAN_DANG_KY_KIEU_DANG_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/dang-ky-kieu-dang-cong-nghiep",
  HUONG_DAN_DANG_KY_NHAN_HIEU: "/thu-tuc-hanh-chinh/dang-ky-nhan-hieu",
  HUONG_DAN_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM:
    "/thu-tuc-hanh-chinh/dang-ky-nhan-hieu-co-nguon-goc-VN",
  HUONG_DAN_DANG_KY_CHI_DAN_DIA_LY:
    "/thu-tuc-hanh-chinh/dang-ky-chi-dan-dia-ly",
  HUONG_DAN_DANG_KY_THIET_KE_BO_TRI_MACH_BAN_DAN:
    "/thu-tuc-hanh-chinh/dang-ky-thiet-ke-bo-tri-mach-ban-dan",

  HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_NHUONG:
    "/thu-tuc-hanh-chinh/dang-ky-hop-dong-chuyen-nhuong",
  HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN:
    "/thu-tuc-hanh-chinh/dang-ky-hop-dong-chuyen-nhuong-quyen-shcn",
  HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN:
    "/thu-tuc-hanh-chinh/dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn",
  HUONG_DAN_SUA_DOI_BO_SUNG_TACH_DON_DANG_KY_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/sua-doi-bo-sung-tach-don-dang-ky-so-huu-cong-nghiep",
  HUONG_DAN_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/yeu-cau-cap-chung-chi-hanh-nghe-dich-vu-dai-dien-shcn",
  HUONG_DAN_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/yeu-cau-cap-lai-chung-chi-hanh-nghe-dich-vu-dai-dien-shcn",
  HUONG_DAN_SUA_DOI_VAN_BANG_BAO_HO:
    "/thu-tuc-hanh-chinh/sua-doi-van-bang-bao-ho",
  HUONG_DAN_YEU_CAU_CUNG_CAP_BAN_SAO_TAI_LIEU_THONG_TIN_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/yeu-cau-cung-cap-ban-sao-tai-lieu-thong-tin-shcn",
  HUONG_DAN_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN:
    "/thu-tuc-hanh-chinh/chan-dut-hieu-luc-van-bang-bao-ho-shcn",
  HUONG_DAN_HUY_BO_HIEU_LUC_VAN_BANG_BAO_HO_SHCN:
    "/thu-tuc-hanh-chinh/huy-bo-hieu-luc-van-bang-bao-ho-SHCN",

  HUONG_DAN_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO:
    "/thu-tuc-hanh-chinh/duy-tri-gia-han-hieu-luc-van-bang-bao-ho",
  HUONG_DAN_GIA_HAN_HIEU_LUC_VAN_BANG_BAO_HO:
    "/thu-tuc-hanh-chinh/duy-tri-gia-han-hieu-luc-van-bang-bao-ho",
  //Tờ khai
  ...FORM_ROUTER,
}
export default ROUTER
